// utils/cookies.ts
import { NextResponse } from 'next/server';

interface CookieOptions {
  maxAge?: number;
  path?: string;
}

export function setCookie(res: NextResponse, name: string, value: string, options: CookieOptions = {}) {
  const cookieOptions = {
    maxAge: 30 * 24 * 60 * 60, // default maxAge of 30 days
    path: '/',
    ...options,
  };

  const cookie = `${name}=${value}; Max-Age=${cookieOptions.maxAge}; Path=${cookieOptions.path}`;
  res.headers.set('Set-Cookie', cookie);
}

export function getCookie(name: string): string | null {
  const nameEQ = name + "=";
  const ca = document.cookie.split(';');
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}