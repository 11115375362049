/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react';
import axios from 'axios';
import { getCookie } from '@/utils/cookies';
import { useAccount } from 'wagmi';
import { getIp } from '@/utils/getIp';

const useMixpanelTracking = () => {
  const { address } = useAccount();

  const track = useCallback(async (event: string, properties: any) => {
    const distinctId = address || getCookie('mixpanel_id') || 'unknown_user';
    try {
      const userIp = await getIp();
      const data: any = {
        event,
        properties: { ...properties, distinct_id: distinctId, ip: userIp || '' },
      };

      await axios.post('/api/mixpanel/track', data);
    } catch (error) {
      console.error('Error tracking event:', error);
    }
  }, [address]);

  const updateProfile = useCallback(async (properties: any) => {
    const distinctId = address || getCookie('mixpanel_id');
    if (distinctId) {
      try {
        const userIp = await getIp();
        const data: any = {
          distinct_id: distinctId,
          properties: { ...properties, ip: userIp || '' },
        };

        await axios.post('/api/mixpanel/update-profile', data);
      } catch (error) {
        console.error('Error updating profile:', error);
      }
    }
  }, [address]);

  return { track, updateProfile };
};

export default useMixpanelTracking;