import { useEffect, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import Typed from 'typed.js';

interface TypingEffectProps {
  text: string;
  children?: React.ReactNode;
  className?: string;
}

const TypingEffect: React.FC<TypingEffectProps> = ({
  text,
  children,
  className,
}) => {
  const typedElement = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const options = {
      strings: [text],
      typeSpeed: 20,
      backSpeed: 25,
      loop: false,
      showCursor: false,
      onComplete: () => {
        if (typedElement.current && children) {
          const container = document.createElement('div');
          container.className = className || '';
          const root = createRoot(container);
          root.render(children);
          typedElement.current.appendChild(container);
        }
      },
    };

    const typed = new Typed(typedElement.current!, options);

    return () => {
      typed.destroy();
    };
  }, [text, children, className]);

  return <div ref={typedElement} />;
};

export default TypingEffect;
