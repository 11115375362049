'use client';

import React, { useState, useEffect, useRef } from 'react';
import styles from './TerminalHeader.module.scss';
import { textToNumberSequence } from '@/utils/text-to-number-sequence';

const Header: React.FC = () => {
  const [currentTime, setCurrentTime] = useState<string>(
    new Date().toLocaleString(),
  );
  const [randomNumberString, setRandomNumberString] =
    useState<string>('000.000.00000');
  const numberIntervalRef = useRef<NodeJS.Timeout | null>(null);

  // Update current time every second
  useEffect(() => {
    const timeInterval = setInterval(() => {
      setCurrentTime(new Date().toLocaleString());
    }, 1000);
    return () => clearInterval(timeInterval);
  }, []);

  // Handle random number sequence effect
  useEffect(() => {
    const message = 'Chad gives you his blessing';
    const numberSequenceMessage = textToNumberSequence(message);
    let charIndex = 0;

    const updateRandomNumberString = () => {
      const defaultString = '000.000.00000';
      const newString = defaultString.split('');

      const validPositions = newString.reduce<number[]>((acc, char, index) => {
        if (char === '0' && defaultString[index] !== '.') acc.push(index);
        return acc;
      }, []);

      if (
        validPositions.length > 0 &&
        charIndex < numberSequenceMessage.length
      ) {
        const randomPosition =
          validPositions[Math.floor(Math.random() * validPositions.length)];
        newString[randomPosition] = numberSequenceMessage[charIndex];
        charIndex++;
      }

      setRandomNumberString(newString.join(''));

      setTimeout(() => {
        setRandomNumberString(defaultString);
      }, 1000);
    };

    if (numberIntervalRef.current) {
      clearInterval(numberIntervalRef.current);
    }

    numberIntervalRef.current = setInterval(updateRandomNumberString, 2000);

    return () => {
      if (numberIntervalRef.current) clearInterval(numberIntervalRef.current);
    };
  }, []);

  return (
    <div className={styles.header}>
      <div>{currentTime.split(',')[0]}</div>
      <div>{currentTime.split(',')[1]}</div>
      <div>ChadGPT</div>
      <div>{randomNumberString}</div>
    </div>
  );
};

export default Header;
