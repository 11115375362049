/* eslint-disable @typescript-eslint/no-unsafe-return */
import axios from 'axios';
import { NextRequest } from 'next/server';

export const getIp = async (req?: NextRequest): Promise<string | null> => {
  if (req) {
    // Server-side
    return (
      req.headers.get('x-forwarded-for')?.split(',')[0] ||
      req.headers.get('x-real-ip') ||
      req.ip ||
      'Unknown'
    );
  } else {
    // Client-side
    try {
      const response = await axios.get('/api/get-user-ip');
      return response.data.ip;
    } catch (error) {
      console.error('Error getting user IP:', error);
      return null;
    }
  }
};