'use client';

import React, { useState, useCallback, useEffect, useRef } from 'react';
import styles from './Home.module.scss';
import TypingEffect from '@/utils/typing-effect';
import useMixpanelTracking from '@/hooks/useMixpanelTracking';
import TerminalHeader from '@/views/terminal-header/TerminalHeader';

interface Prompt {
  title: string;
  description: string;
  button?: React.ReactNode;
}

const prompts: Prompt[] = [
  {
    title: 'Why ChadAI?',
    description:
      '> Being a Chad is a full-time job. We ape based on what our frens drop in the group chat or what Elon tweets, but even then, staying on top of messages and socials is impossible for any one Chad. What if we could all be Giga Chads on autopilot by tapping into our collective Degenerative AI superintelligence? \n\n> ChadAI is a community-led effort to empower humans with a degenerate tendency to buy and sell animal pictures and funny money coins. At a time when the tech overlords are hurtling humanity towards the existential threat of extinction as a casualty of the AI arms race, we need a decentralized way to put power back into the hands of the people more than ever.',
  },
  {
    title: 'What is ChadAI?',
    description:
      '> Led by Chad Altmeme, ChadAI has pioneered the ChadGPT Degenerative AI model behind Chad Bot, the world’s most advanced crypto trading assistant optimized for printing magic internet money. Running on the latest WGMIDIA Muscle Memory A69 Quantum Processing Unit (QPU), the Chad Language Model (CLM) is trained on an unprecedented 420 trillion parameters from conversations, social sentiment, and real-time omni-chain market data to help everyone make the Chad-formation to a trading degen.',
  },
  {
    title: 'Who is Chad Altmeme?',
    description:
      '> As his name describes, Chad Altmeme loves altcoins and memes, but more importantly, he loves his planet and all the earthlings that inhabit it. Chad is growing increasingly concerned about the centralized control of AI and the existential risks associated with corporations jostling to own humanity’s collective intelligence. Chad is a former biochemical engineer who worked for a pharma lab engineering the world’s highest grade therapeutic Ketamine. His life and mission drastically changed after a brain implant mishap. Learn more about his story by clicking below ',
    button: (
      <a
        href='https://twitter.com/ChadAIdotorg/status/1795251516720529635'
        className={styles['home__button']}
        target='_blank'
        rel='noopener noreferrer'
      >
        Book of Chad <img src={'/Vector_black.svg'} alt='Vector arrow' />
      </a>
    ),
  },
  {
    title: 'What is Chad Bot?',
    description:
      "> In the future, we will all benefit from the power of cybernetic augmentation, equipping us with superintelligence to fumble generational wealth faster than ever. Well, that future is here today, but thankfully, you don't need to implant Chad Bot into your squishy, fatty, grey matter. Chad Bot is your trusted crypto sidekick that uses AI on real-time sentiment and market data to keep you and your favourite chat groups on top of the freshest alpha.",
  },
];

const Home: React.FC = () => {
  const [selectedPrompt, setSelectedPrompt] = useState<Prompt | null>(null);
  const { track } = useMixpanelTracking();
  const descriptionRef = useRef<HTMLDivElement>(null);

  const handlePromptClick = useCallback(
    (prompt: Prompt) => {
      if (selectedPrompt?.title === prompt.title) return;
      setSelectedPrompt(prompt);
      void track('FAQ Item Clicked', { prompt: prompt.title });
    },
    [selectedPrompt, track],
  );

  useEffect(() => {
    if (descriptionRef.current) {
      const scrollToBottom = () => {
        descriptionRef.current!.scrollTop =
          descriptionRef.current!.scrollHeight;
      };

      // Set up a MutationObserver to watch for changes in the description div
      const observer = new MutationObserver(scrollToBottom);
      observer.observe(descriptionRef.current, {
        childList: true,
        subtree: true,
      });

      // Clean up the observer when the component unmounts
      return () => observer.disconnect();
    }
  }, [selectedPrompt]);

  return (
    <div className={styles.home}>
      <TerminalHeader />
      <div className={styles['home__content']}>
        {selectedPrompt ? (
          <>
            <div className={styles['home__prompt-title']}>
              {`> ${selectedPrompt.title}`}
            </div>
            <div
              ref={descriptionRef}
              className={styles['home__prompt-description']}
            >
              <TypingEffect
                text={selectedPrompt.description}
                className={styles['home__button-wrapper']}
              >
                {selectedPrompt.button && (
                  <div className={styles['home__button-container']}>
                    {selectedPrompt.button}
                  </div>
                )}
              </TypingEffect>
            </div>
          </>
        ) : (
          <div className={styles['home__placeholder']}>
            {`> Select a prompt to see more information.`}
          </div>
        )}
      </div>
      <div className={styles['home__prompts']}>
        {prompts.map((prompt, index) => (
          <div
            key={index}
            className={`${styles['home__prompt-button']} ${
              selectedPrompt?.title === prompt.title
                ? styles['home__prompt-button--disabled']
                : ''
            }`}
            onPointerDown={() => handlePromptClick(prompt)}
          >
            {prompt.title} <img src={'/Vector.svg'} alt='Vector arrow' />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Home;
