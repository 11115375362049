'use client';

import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useCallback,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react';
import Home from '@/views/home/Home';
import styles from '../app/page.module.scss';

interface UIContextType {
  powerIcon: string;
  whatsappIcon: string;
  telegramIcon: string;
  discordIcon: string;
  homeIcon: string;
  stakingIcon: string;
  tokenIcon: string;
  memesIcon: string;
  nftIcon: string;
  networkIcon: string;
  shopIcon: string;
  daoIcon: string;
  isPowerOn: boolean;
  isLoading: boolean;
  activeButton: string;
  screenContent: ReactNode;
  setPowerIcon: Dispatch<SetStateAction<string>>;
  setWhatsappIcon: Dispatch<SetStateAction<string>>;
  setTelegramIcon: Dispatch<SetStateAction<string>>;
  setDiscordIcon: Dispatch<SetStateAction<string>>;
  setHomeIcon: Dispatch<SetStateAction<string>>;
  setStakingIcon: Dispatch<SetStateAction<string>>;
  setTokenIcon: Dispatch<SetStateAction<string>>;
  setMemesIcon: Dispatch<SetStateAction<string>>;
  setNftIcon: Dispatch<SetStateAction<string>>;
  setNetworkIcon: Dispatch<SetStateAction<string>>;
  setShopIcon: Dispatch<SetStateAction<string>>;
  setDaoIcon: Dispatch<SetStateAction<string>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setScreenContent: Dispatch<SetStateAction<ReactNode>>;
  setActiveButton: Dispatch<SetStateAction<string>>;
  pressButton: (button: string) => void;
  togglePowerButton: () => void;
}

const UIContext = createContext<UIContextType | undefined>(undefined);

interface UIProviderProps {
  children: ReactNode;
}

export const UIProvider: React.FC<UIProviderProps> = ({ children }) => {
  const [powerIcon, setPowerIcon] = useState<string>(
    '/terminal-keys/power-rest.webp',
  );
  const [whatsappIcon, setWhatsappIcon] = useState<string>(
    '/terminal-keys/whatsapp-broken-rest.webp',
  );
  const [telegramIcon, setTelegramIcon] = useState<string>(
    '/terminal-keys/telegram-broken-rest.webp',
  );
  const [discordIcon, setDiscordIcon] = useState<string>(
    '/terminal-keys/discord-broken-rest.webp',
  );
  const [homeIcon, setHomeIcon] = useState<string>(
    '/terminal-keys/home-rest.webp',
  );
  const [stakingIcon, setStakingIcon] = useState<string>(
    '/terminal-keys/staking-rest.webp',
  );
  const [tokenIcon, setTokenIcon] = useState<string>(
    '/terminal-keys/token-rest.webp',
  );
  const [memesIcon, setMemesIcon] = useState<string>(
    '/terminal-keys/memes-rest.webp',
  );
  const [nftIcon, setNftIcon] = useState<string>(
    '/terminal-keys/nft-rest.webp',
  );
  const [networkIcon, setNetworkIcon] = useState<string>(
    '/terminal-keys/network-broken-rest.webp',
  );
  const [shopIcon, setShopIcon] = useState<string>(
    '/terminal-keys/shop-broken-rest.webp',
  );
  const [daoIcon, setDaoIcon] = useState<string>(
    '/terminal-keys/dao-broken-rest.webp',
  );
  const [isLoading, setLoading] = useState<boolean>(false);
  const [screenContent, setScreenContent] = useState<ReactNode>(null);
  const [activeButton, setActiveButton] = useState<string>('home');
  const isPowerOn = powerIcon === '/terminal-keys/power-pressed.webp';

  const pressButton = useCallback(
    (button: string) => {
      if (!isPowerOn) return;

      setActiveButton(button);
      setWhatsappIcon(
        button === 'whatsapp'
          ? '/terminal-keys/whatsapp-broken-pressed.webp'
          : '/terminal-keys/whatsapp-broken-rest.webp',
      );
      setTelegramIcon(
        button === 'telegram'
          ? '/terminal-keys/telegram-broken-pressed.webp'
          : '/terminal-keys/telegram-broken-rest.webp',
      );
      setDiscordIcon(
        button === 'discord'
          ? '/terminal-keys/discord-broken-pressed.webp'
          : '/terminal-keys/discord-broken-rest.webp',
      );
      setHomeIcon(
        button === 'home'
          ? '/terminal-keys/home-pressed.webp'
          : '/terminal-keys/home-rest.webp',
      );
      setStakingIcon(
        button === 'staking'
          ? '/terminal-keys/staking-pressed.webp'
          : '/terminal-keys/staking-rest.webp',
      );
      setTokenIcon(
        button === 'token'
          ? '/terminal-keys/token-pressed.webp'
          : '/terminal-keys/token-rest.webp',
      );
      setMemesIcon(
        button === 'memes'
          ? '/terminal-keys/memes-pressed.webp'
          : '/terminal-keys/memes-rest.webp',
      );
      setNftIcon(
        button === 'nft'
          ? '/terminal-keys/nft-pressed.webp'
          : '/terminal-keys/nft-rest.webp',
      );
      setNetworkIcon(
        button === 'network'
          ? '/terminal-keys/network-broken-pressed.webp'
          : '/terminal-keys/network-broken-rest.webp',
      );
      setShopIcon(
        button === 'shop'
          ? '/terminal-keys/shop-broken-pressed.webp'
          : '/terminal-keys/shop-broken-rest.webp',
      );
      setDaoIcon(
        button === 'dao'
          ? '/terminal-keys/dao-broken-pressed.webp'
          : '/terminal-keys/dao-broken-rest.webp',
      );
    },
    [isPowerOn],
  );

  const resetButtons = () => {
    setWhatsappIcon('/terminal-keys/whatsapp-broken-rest.webp');
    setTelegramIcon('/terminal-keys/telegram-broken-rest.webp');
    setDiscordIcon('/terminal-keys/discord-broken-rest.webp');
    setHomeIcon('/terminal-keys/home-rest.webp');
    setStakingIcon('/terminal-keys/staking-rest.webp');
    setTokenIcon('/terminal-keys/token-rest.webp');
    setMemesIcon('/terminal-keys/memes-rest.webp');
    setNftIcon('/terminal-keys/nft-rest.webp');
    setNetworkIcon('/terminal-keys/network-broken-rest.webp');
    setShopIcon('/terminal-keys/shop-broken-rest.webp');
    setDaoIcon('/terminal-keys/dao-broken-rest.webp');
    setScreenContent(null);
    setActiveButton('home');
  };

  const togglePowerButton = useCallback(() => {
    setPowerIcon((prev) => {
      const newIcon =
        prev === '/terminal-keys/power-rest.webp'
          ? '/terminal-keys/power-pressed.webp'
          : '/terminal-keys/power-rest.webp';

      if (newIcon === '/terminal-keys/power-rest.webp') {
        resetButtons();
      } else {
        setScreenContent(
          <div className={styles.loadingContainer}>
            <img
              src='/logo/chad-loading.gif'
              alt='Loading'
              className={styles.loadingGif}
              onLoad={() => {
                setTimeout(() => {
                  if (powerIcon === '/terminal-keys/power-rest.webp') {
                    setLoading(false);
                    setScreenContent(<Home />);
                    setHomeIcon('/terminal-keys/home-pressed.webp');
                  }
                }, 4000);
              }}
            />
            <div className={styles.loadingText}>
              Initializing ChadOS<span className={styles.loadingDots}></span>
            </div>
          </div>,
        );
      }

      return newIcon;
    });
  }, [powerIcon]);

  const contextValue = useMemo(
    () => ({
      powerIcon,
      whatsappIcon,
      telegramIcon,
      discordIcon,
      homeIcon,
      stakingIcon,
      tokenIcon,
      memesIcon,
      nftIcon,
      networkIcon,
      shopIcon,
      daoIcon,
      isPowerOn,
      isLoading,
      activeButton,
      screenContent,
      setPowerIcon,
      setWhatsappIcon,
      setTelegramIcon,
      setDiscordIcon,
      setHomeIcon,
      setStakingIcon,
      setTokenIcon,
      setMemesIcon,
      setNftIcon,
      setNetworkIcon,
      setShopIcon,
      setDaoIcon,
      setLoading,
      setScreenContent,
      setActiveButton,
      pressButton,
      togglePowerButton,
    }),
    [
      powerIcon,
      whatsappIcon,
      telegramIcon,
      discordIcon,
      homeIcon,
      stakingIcon,
      tokenIcon,
      memesIcon,
      nftIcon,
      networkIcon,
      shopIcon,
      daoIcon,
      isPowerOn,
      isLoading,
      activeButton,
      screenContent,
      pressButton,
      togglePowerButton,
    ],
  );

  return (
    <UIContext.Provider value={contextValue}>{children}</UIContext.Provider>
  );
};

export const useUIContext = (): UIContextType => {
  const context = useContext(UIContext);
  if (context === undefined) {
    throw new Error('useUIContext must be used within a UIProvider');
  }
  return context;
};
